<template>
  <content-layout
    class="profile-page"
    :locations-list="userLocations"
    :current-location="currentUserLocation"
    @location-change="locationChangeHandler"
  >
    <template #mobile-controls>
      <main-side-menu
        :item-list="menuItems"
      />
    </template>
    <template #main-content>
      <div class="profile-page__content">
        <div class="profile-page__avatar-block">
          <profile-avatar
            :user-name="userFIO"
            :contract-number="userContract"
            :avatar-url="userAvatar"
            @upload-avatar="uploadAvatar"
            @delete-avatar="deleteAvatar"
          />
        </div>
        <div class="profile-page__settings-block">
          <profile-settings
            requestOnly
            :title="t('profilePage.baseData')"
            :settings="baseSettings"
            @change-data="openRequestPopup('Запросить смену данных')"
          />
        </div>
        <div
          class="profile-page__settings-block"
          v-if="organizationType !== `Физ.лицо`"
        >
          <profile-settings
            requestOnly
            :title="t('profilePage.bankData')"
            :settings="bankSettings"
            @change-data="openRequestPopup('Запросить смену реквизитов')"
          />
        </div>
        <div class="profile-page__settings-block">
          <profile-password
            :title="t('profilePage.security')"
            @open-modal-window="openPopup"
          />
        </div>
<!--        <div class="profile-page__settings-block">-->
<!--          <profile-two-factor-authentication-->
<!--            requestOnly-->
<!--            :title="t('profilePage.twoFactorAuth')"-->
<!--            :settings="baseSettings"-->
<!--            @change-data="openAuthModal(true)"-->
<!--          />-->
<!--        </div>-->

        <modal-window
          :is-shown="modalShowAuth"
          @close-window="openAuthModal(false)"
        >
          <div  class="modal" @click="openAuthModal(false)">
            <div class="modal-content" @click.stop>
              <h1 class="sellAssetText">
                Аутентификация
              </h1>
              <div class="sellAssetDescription">
                <span v-html="t('authModalTitle')"></span>
                <span>{{baseSettings.phone.value}}.</span>
                <p v-html="t('authModalSubTitle')"></p>
              </div>
              <p>
                <span v-html="t('dontReceavedCode')"></span>
                <span
                  v-html="t('resendCode')"
                  class="blueText"
                  :class="{ disabled: isResendDisabled }"
                  @click="startResendTimer"
                ></span>

              </p>

              <form-field
                class="password-change-form__input-field"
                input-type="number"
                :label="t('code')"
              />

              <div class="sellActions">
                <button class="cancelBtn" @click="openAuthModal(false)">
                  <img
                    src="../assets/cross-circle.svg"
                    alt="cancel"
                  >
                  {{t("cancel")}}
                </button>
                <button class="sendBtn" >
                  <img
                    src="../assets/check-circle.svg"
                    alt="send"
                  >
                  {{t("send")}}
                </button>
              </div>
            </div>
          </div>

        </modal-window>

        <modal-window
          :is-shown="modalIsShown"
          @close-window="closePopup"
        >
          <password-change-form
            :success-view="isSuccessPasswordChange"
            @change-password="changePasswordHandler"
            @cancel-password-change="closePopup"
          />
        </modal-window>
        <modal-window
          :is-shown="requestModalIsShown"
          @close-window="closeRequestPopup"
        >
          <request-form
            :title="requestPopupTitle"
            :success-view="isSuccessRequestSend"
            @send-request="sendRequestToChangeProfileSettings"
            @cancel-request="closeRequestPopup"
          />
        </modal-window>
      </div>
    </template>
  </content-layout>
</template>

<script>
import { computed, provide, ref } from 'vue';
import { computedAsync } from '@vueuse/core';
import { useStore } from 'vuex';
import ContentLayout from '@/layouts/ContentLayout.vue';
import MainSideMenu from '@/components/MainSideMenu/MainSideMenu.vue';
import ProfileSettings from '@/components/ProfileSettings/ProfileSettings.vue';
import ProfilePassword from '@/components/ProfilePassword/ProfilePassword.vue';
import ProfileAvatar from '@/components/ProfileAvatar/ProfileAvatar.vue';
import ModalWindow from '@/components/ModalWindow/ModalWindow.vue';
import PasswordChangeForm from '@/components/PasswordChangeForm/PasswordChangeForm.vue';
import RequestForm from '@/components/RequestForm/RequestForm.vue';
import { useRoute } from 'vue-router';
import userModel from '@/models/user';
import { useI18n } from 'vue-i18n';
import translates from '@/views/translates';
import ProfileTwoFactorAuthentication
  from '@/components/ProfileTwoFactorAuthentication/ProfileTwoFactorAuthentication.vue';
import FormField from '@/ui-kit/FormField/FormField.vue';

export default {
  name: 'ProfilePage',
  components: {
    FormField,
    // eslint-disable-next-line vue/no-unused-components
    ProfileTwoFactorAuthentication,
    ContentLayout,
    ProfileSettings,
    ProfilePassword,
    ProfileAvatar,
    ModalWindow,
    PasswordChangeForm,
    MainSideMenu,
    RequestForm,
  },
  setup() {
    const store = useStore();
    const { t, mergeLocaleMessage } = useI18n();
    mergeLocaleMessage('en', translates.en);
    mergeLocaleMessage('ru', translates.ru);
    const userState = store.state.user;
    const userLocations = computed(() => userState.locations
      .map((location) => ({ ...location, label: location.name })));
    const userFIO = computed(() => userState.profileSettings.fio);
    const currentUserLocation = computed(() => userState.currentLocation);
    const userContract = computed(() => userState.profileSettings.contractNumber);
    const userAvatar = computed(() => userState.profileSettings.avatar);
    const organizationType = computed(() => userState.organizationType);
    const baseSettings = computedAsync(() => store.getters['user/baseProfileSettings'], {});
    const bankSettings = computedAsync(() => organizationType.value === 'ИП'
      ? store.getters['user/bankProfileSettingsIP']
      : store.getters['user/bankProfileSettingsOOO'], {});
    const menuItems = computed(() => userState.sideMenuItems);
    const modalIsShown = ref(false);
    const modalShowAuth = ref(false);
    const isSuccessPasswordChange = ref(false);
    const requestModalIsShown = ref(false);
    const isSuccessRequestSend = ref(false);
    const errorMessage = ref('');
    const requestPopupTitle = ref('');
    provide('globalErrorMessage', computed(() => errorMessage));

    const isResendDisabled = ref(false);
    const resendCodeTimer = ref(30);
    const resendCodeText = ref('Resend Code');

    const startResendTimer = () => {
      if (isResendDisabled.value) return;

      isResendDisabled.value = true;
      resendCodeText.value = `Resend available in ${resendCodeTimer.value}s`;

      const timer = setInterval(() => {
        resendCodeTimer.value -= 1;
        if (resendCodeTimer.value > 0) {
          resendCodeText.value = `Resend available in ${resendCodeTimer.value}s`;
        } else {
          clearInterval(timer);
          resendCodeTimer.value = 30; // Reset timer
          resendCodeText.value = 'Resend Code';
          isResendDisabled.value = false;
        }
      }, 1000);
    };

    const initProfileSetting = async () => {
      store.dispatch('base/setCurrentPath', useRoute());
      await store.dispatch('user/initUsersLocations');
      await store.dispatch('user/getUserProfileSettings');
      await store.dispatch('user/initSideMenuItems');
    };

    const saveBaseSettings = async (settings) => {
      await store.dispatch('user/updateUserProfileSettings', settings);
    };

    const saveBankSettings = async (settings) => {
      await store.dispatch('user/updateUserProfileSettings', settings);
    };

    const uploadAvatar = async (avatarBase64) => {
      await store.dispatch('user/updateUserProfileSettings', [{
        id: 'avatar',
        currentValue: avatarBase64,
      }]);
    };

    const deleteAvatar = async () => {
      await store.dispatch('user/updateUserProfileSettings', [{
        id: 'avatar',
        currentValue: '',
      }]);
    };

    const changePasswordHandler = async (payload) => {
      const resp = await store.dispatch('user/changePassword', payload);

      if (resp.ok) {
        errorMessage.value = '';
        isSuccessPasswordChange.value = true;
      } else {
        errorMessage.value = resp.data.message;
      }
    };

    const closePopup = () => {
      modalIsShown.value = false;
    };

    const openPopup = () => {
      modalIsShown.value = true;
    };

    console.log(baseSettings, 'baseSettings');

    const openRequestPopup = (title) => {
      requestPopupTitle.value = title;
      requestModalIsShown.value = true;
    };

    const openAuthModal = (open) => {
      modalShowAuth.value = open;
    };

    const closeRequestPopup = () => {
      requestModalIsShown.value = false;
    };

    const locationChangeHandler = (id) => {
      store.dispatch('user/setCurrentLocation', id);
      initProfileSetting();
    };

    const sendRequestToChangeProfileSettings = async () => {
      const resp = await userModel.requestToChangeProfileSettings();

      if (resp.ok) {
        isSuccessRequestSend.value = true;
        setTimeout(() => {
          closeRequestPopup();
          isSuccessRequestSend.value = false;
        }, 2000);
      }
    };

    initProfileSetting();

    return {
      t,
      menuItems,
      userLocations,
      userContract,
      baseSettings,
      bankSettings,
      currentUserLocation,
      modalIsShown,
      isSuccessPasswordChange,
      saveBaseSettings,
      saveBankSettings,
      uploadAvatar,
      deleteAvatar,
      changePasswordHandler,
      openPopup,
      closePopup,
      openRequestPopup,
      closeRequestPopup,
      isSuccessRequestSend,
      requestModalIsShown,
      locationChangeHandler,
      organizationType,
      sendRequestToChangeProfileSettings,
      requestPopupTitle,
      userFIO,
      userAvatar,
      modalShowAuth,
      openAuthModal,
      isResendDisabled,
      resendCodeText,
      startResendTimer,
    };
  },
};
</script>

<style lang="scss" scoped>
.profile-page {
  &__content {
    width: 100%;
  }

  &__settings-block {
    margin-top: 70px;

    &:first-child {
      margin-top: 0;
    }
  }
}
.blueText {
  text-decoration: none;
  color: rgb(43, 84, 226);
  cursor: pointer;
}

@media screen and (max-width: $tablet-breakpoint) {
  .header-menu {
    margin-right: 48px;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .profile-page {
    &__content {
      padding-top: 40px;
    }

    &__settings-block {
      margin-top: 36px;
    }
  }
}
</style>
