<template>
  <div class="lang-toggler__wrapper">
    <div class="lang-toggler">
      <button
        v-for="locale in localeList"
        :key="locale"
        class="lang-toggler__button"
        :class="{
        'lang-toggler__button--inactive': preselectedValue === locale,
      }"
        @click="langChange(locale)"
      >
        {{ localeMap[locale] || locale }}
      </button>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

const localeMap = {
  ru: 'Ру',
  en: 'En',
};

export default {
  name: 'LangToggler',
  props: {
    preselectedValue: {
      type: String,
      default: '',
    },
    localeList: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['langChange'],
  setup(props, { emit }) {
    const selectedValue = computed(() => props.preselectedValue && props.preselectedValue.length
      ? props.preselectedValue
      : null);
    const langChange = (lang) => {
      if (lang === 'en') {
        window.location.href = 'https://investor.inwis.ltd';
        return;
      }
      emit('langChange', lang);
      selectedValue.value = lang;
    };

    return {
      langChange,
      selectedValue,
      localeMap,
    };
  },
};
</script>

<style scoped lang="scss">
.lang-toggler {
  display: flex;

  &__wrapper {

  }

  &__button {
    padding: 4px;
    margin-left: 4px;
    color: $blue500;
    font-size: $font-size-small;
    font-weight: $font-weight-medium;
    border: none;
    background: transparent;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }

    &--inactive {
      outline: none;
      pointer-events: none;
      cursor: auto;
      color: $grey700;
    }
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .lang-toggler {

  }
}
</style>
