<template>
  <div class="profile-settings">
    <div class="profile-settings__header">
      <title-secondary
        :text="title"
        class="profile-settings__header-text"
      />
      <div class="profile-settings__header-buttons">
        <template v-if="!isEditingMode">
          <secondary-button
            v-if="!requestOnly"
            class="profile-settings__header-button"
            :label="$t('change')"
            @click="editingModeToggle"
          >
            <template #iconBefore>
              <svg width="13" height="12" viewBox="0 0 13 12" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1895_5192)">
                  <path d="M9.82702 7.73651C9.50465 7.73651 9.2441 7.99709 9.2441
                  8.31944V10.1505C9.2441 10.4723 8.98294 10.7334
                  8.66117 10.7334H2.24881C1.92701 10.7334 1.66588 10.4722 1.66588
                  10.1505V2.57227C1.66588
                  2.25048 1.92704 1.98934 2.24881 1.98934H5.63568C5.95803 1.98934 6.2186 1.72877
                  6.2186 1.40641C6.2186 1.08406 5.95803 0.823486 5.63568 0.823486H2.24881C1.28463
                  0.823439 0.5 1.60809 0.5 2.57227V10.1505C0.5 11.1146 1.28463 11.8993 2.24881
                  11.8993H8.66112C9.6253 11.8993 10.4099 11.1152 10.4099 10.1505V8.31946C10.41
                  7.99709 10.1494 7.73651 9.82702 7.73651Z"
                  />
                  <path d="M11.8483 0.749479C11.4251 0.326275 10.8724 0.112904 10.3157
                  0.101256C9.72986 0.0884356 9.14051 0.300612 8.69748 0.744229L4.75098
                  4.69073C4.17504 5.26902 3.85791 6.03616 3.85791 6.85109V8.15687C3.85791
                  8.47922 4.11848 8.73979 4.44084 8.73979H5.74661C6.56157 8.73979 7.32871
                  8.42267 7.90756 7.84614L11.8488 3.90488C12.7174 3.03577 12.7174 1.61923
                  11.8483 0.749479ZM7.08391 7.02134C6.72656 7.37751 6.25088 7.57396 5.74666
                  7.57396H5.02379V6.85111C5.02379 6.34687 5.22024 5.87119 5.57583 5.51443L8.17748
                  2.91277L9.68496 4.42024L7.08391 7.02134ZM11.024 3.08123L10.5092 3.59598L9.00176
                  2.08851L9.51649 1.57378C9.93271 1.15757 10.6089 1.15874 11.024 1.57378C11.439
                  1.98939 11.439 2.6656 11.024 3.08123Z"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1895_5192">
                    <rect width="12" height="12" transform="translate(0.5)"/>
                  </clipPath>
                </defs>
              </svg>

            </template>
          </secondary-button>
          <secondary-button
            v-else
            class="profile-settings__header-button"
            label="Включить двухфакторную аутенфикацию "
            @click="changeData"
          >
            <template #iconBefore>
              <svg width="13" height="12" viewBox="0 0 13 12" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1895_5192)">
                  <path d="M9.82702 7.73651C9.50465 7.73651 9.2441 7.99709 9.2441
                  8.31944V10.1505C9.2441 10.4723 8.98294 10.7334
                  8.66117 10.7334H2.24881C1.92701 10.7334 1.66588 10.4722 1.66588
                  10.1505V2.57227C1.66588
                  2.25048 1.92704 1.98934 2.24881 1.98934H5.63568C5.95803 1.98934 6.2186 1.72877
                  6.2186 1.40641C6.2186 1.08406 5.95803 0.823486 5.63568 0.823486H2.24881C1.28463
                  0.823439 0.5 1.60809 0.5 2.57227V10.1505C0.5 11.1146 1.28463 11.8993 2.24881
                  11.8993H8.66112C9.6253 11.8993 10.4099 11.1152 10.4099 10.1505V8.31946C10.41
                  7.99709 10.1494 7.73651 9.82702 7.73651Z"
                  />
                  <path d="M11.8483 0.749479C11.4251 0.326275 10.8724 0.112904 10.3157
                  0.101256C9.72986 0.0884356 9.14051 0.300612 8.69748 0.744229L4.75098
                  4.69073C4.17504 5.26902 3.85791 6.03616 3.85791 6.85109V8.15687C3.85791
                  8.47922 4.11848 8.73979 4.44084 8.73979H5.74661C6.56157 8.73979 7.32871
                  8.42267 7.90756 7.84614L11.8488 3.90488C12.7174 3.03577 12.7174 1.61923
                  11.8483 0.749479ZM7.08391 7.02134C6.72656 7.37751 6.25088 7.57396 5.74666
                  7.57396H5.02379V6.85111C5.02379 6.34687 5.22024 5.87119 5.57583 5.51443L8.17748
                  2.91277L9.68496 4.42024L7.08391 7.02134ZM11.024 3.08123L10.5092 3.59598L9.00176
                  2.08851L9.51649 1.57378C9.93271 1.15757 10.6089 1.15874 11.024 1.57378C11.439
                  1.98939 11.439 2.6656 11.024 3.08123Z"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1895_5192">
                    <rect width="12" height="12" transform="translate(0.5)"/>
                  </clipPath>
                </defs>
              </svg>

            </template>
          </secondary-button>
        </template>
        <template
          v-else
        >
          <secondary-button
            :label="$t('cancel')"
            class="profile-settings__header-button"
            @click="cancelChanges"
          >
            <template #iconBefore>
              <svg width="13" height="12" viewBox="0 0 13 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.25729 1.75736C-0.0821968 4.09685 -0.0821968 7.90315 2.25729
                10.2426C4.59677 12.5821 8.40308 12.5821 10.7426 10.2426C13.0821 7.90315 13.0821
                4.09685 10.7426 1.75736C8.40308 -0.582126 4.59677 -0.582126 2.25729 1.75736ZM8.70962
                4.49738L7.20699 6L8.70962 7.50262C8.9048 7.69781 8.9048 8.0145 8.70962
                8.20969C8.51443 8.40487 8.19774 8.40487 8.00255 8.20969L6.49993 6.70706L4.99731
                8.20969C4.80212 8.40487 4.48543 8.40487 4.29024 8.20969C4.09506 8.0145
                4.09506 7.69781 4.29024 7.50262L5.79287 6L4.29024 4.49738C4.09506 4.30219 4.09506
                3.9855 4.29024 3.79031C4.48543 3.59513 4.80212 3.59513 4.99731 3.79031L6.49993
                5.29294L8.00255 3.79031C8.19774 3.59513 8.51443 3.59513 8.70962 3.79031C8.9048
                3.9855 8.9048 4.30219 8.70962 4.49738Z"/>
              </svg>

            </template>
          </secondary-button>
          <secondary-button
            class="profile-settings__header-button"
            green
            :label="t('save')"
            @click="saveSettings"
          >
            <template #iconBefore>
              <svg width="13" height="12" viewBox="0 0 13 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 0C3.19147 0 0.5 2.69147 0.5 6C0.5 9.30853 3.19147 12 6.5 12C9.80853
                12 12.5 9.30853 12.5 6C12.5 2.69147 9.80853 0 6.5 0ZM9.54102 4.72852L6.29099
                7.97845C6.19348 8.07596 6.06549 8.12503 5.9375 8.12503C5.80951 8.12503 5.68152
                8.07596 5.58401 7.97845L3.95905 6.35349C3.76349 6.15802 3.76349 5.84198 3.95905
                5.64651C4.15451 5.45096 4.47046 5.45096 4.66602 5.64651L5.9375 6.918L8.83405
                4.02155C9.02951 3.82599 9.34546 3.82599 9.54102 4.02155C9.73648 4.21701 9.73648
                4.53296 9.54102 4.72852Z"
                />
              </svg>
            </template>
          </secondary-button>
        </template>
      </div>
    </div>
    <div class="profile-settings__body">
      <ul class="profile-settings__rows">
        <li
          v-for="item in settingsState"
          :key="item.id"
          class="profile-settings__row"
        >
          <div class="profile-settings__label">
            {{ item.label }}
          </div>
          <div class="profile-settings__value">
            <form-field
              v-if="isEditingMode && item.editable"
              class="profile-settings__field"
              secondary
              :input-type="item.inputType || 'text'"
              v-model="item.currentValue"
              :placeholder="item.currentValue || $t('notSpecified')"
              :preselected-value="item.value"
              :is-error="item.isError"
              :is-show-message="item.isShowMessage"
              :message="item.message"
            />
            <span
              v-else
              class="profile-settings__value-text"
            >
              {{ item.currentValue || $t('notSpecified') }}
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>

</template>

<script>
import { ref, toRaw, watch } from 'vue';
import TitleSecondary from '@/ui-kit/TitleSecondary/TitleSecondary.vue';
import SecondaryButton from '@/ui-kit/SecondaryButton/SecondaryButton.vue';
import formField from '@/ui-kit/FormField/FormField.vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'ProfileTwoFactorAuthentication',
  components: {
    TitleSecondary,
    SecondaryButton,
    formField,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
    requestOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['saveSettings', 'changeData'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const isEditingMode = ref(false);
    const initialFieldState = {
      isError: false,
      isShowMessage: false,
      message: '',
    };

    const settingsState = ref([]);

    watch(() => props.settings, (currSettings) => {
      settingsState.value = Object.keys(currSettings).map((key) => ({
        ...currSettings[key],
        ...initialFieldState,
        currentValue: currSettings[key].value || '',
      }));
    });

    const editingModeToggle = () => {
      isEditingMode.value = !isEditingMode.value;
    };

    const cancelChanges = () => {
      settingsState.value = settingsState.value.map((settingsItem) => ({
        ...settingsItem,
        ...initialFieldState,
        currentValue: settingsItem.value,
      }));
      editingModeToggle();
    };

    const saveSettings = () => {
      const changedSettings = settingsState.value
        .filter((settingsItem) => settingsItem.value !== settingsItem.currentValue);
      const preparedChangedSettings = changedSettings.length
        ? changedSettings.map((item) => toRaw(item))
        : [];
      emit('saveSettings', preparedChangedSettings);
      editingModeToggle();
    };

    const changeData = () => {
      emit('changeData');
    };

    return {
      isEditingMode,
      settingsState,
      editingModeToggle,
      cancelChanges,
      saveSettings,
      changeData,
      t,
    };
  },
};
</script>

<style lang="scss">
.profile-settings {
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    border-bottom: 1px solid $grey200;

    &-buttons {
      display: flex;
    }

    &-button {
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__rows {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-top: 24px;
    list-style: none;
  }

  &__row {
    display: flex;
    height: 30px;
    margin-bottom: 28px;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__label {
    width: 210px;
    padding-right: 8px;
    //overflow: hidden;
    font-size: $font-size-medium;
    //white-space: nowrap;
    //text-overflow: ellipsis;
  }

  &__value {
    &-text {
      display: inline-block;
      color: $grey800;
      height: 22px;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .profile-settings {
    &__header {
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      border: none;
    }

    &__header-text {
      font-size: $font-size-title-small;
      line-height: 24px;
      display: flex;

      &::before {
        content: url('@/assets/mobileSecTitleBefore.svg');
        width: 16px;
        height: 16px;
        margin: 0 8px 0 0;
        padding-top: 1px;
      }
    }

    &__header-buttons {
      font-size: $font-size-small;
      margin-top: 10px;
    }

    &__rows {
      margin: 24px 0 0 0;
    }

    &__row {
      flex-direction: column;
      margin-bottom: 23px;
      align-items: flex-start;
      height: 100%;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__label {
      width: 100%;
      padding-right: 0;
      font-size: $font-size-regular;
      font-weight: $font-weight-medium;
      line-height: 20px;
    }

    &__value {
      &-text {
        color: $grey800;
        height: 20px;
        font-size: $font-size-regular;
        font-weight: $font-weight-medium;
      }
    }
  }
}
</style>
