import dashboardModel from '@/models/dashboard';
import { MONTHS_SHORT_NAMES } from '@/constants/dates';
import { generateMonthArray, prepareShortMonthDate, prepareToYYYYMMDD } from '@/helpers/datesAndTime';

export default {
  namespaced: true,
  state: () => ({
    invested: null,
    totalIncome: null,
    averagePercentForYear: null,
    averageMonthlyIncome: null,
    bookingGraphData: [],
    bookingGraphDateRange: [],
    actualDataAll: [],
    actualDataDates: [],
    actualDataSelectedDate: null,
    actualDataInSelectedDate: null,
    isLoadingGraph: false,
    isLoadingActualData: false,
  }),
  mutations: {
    'set:dashboard:statistic': function (state, {
      invested,
      totalIncome,
      averagePercentForYear,
      averageMonthlyIncome,
    } = {}) {
      state.invested = invested || null;
      state.totalIncome = totalIncome || null;
      state.averagePercentForYear = averagePercentForYear || null;
      state.averageMonthlyIncome = averageMonthlyIncome || null;
    },
    'set:dashboard:booking-graph-data': function (state, data) {
      state.bookingGraphData = data;
    },
    'set:dashboard:booking-graph-date-range': function (state, dates) {
      state.bookingGraphDateRange = dates;
    },
    'set:dashboard:actual-data-all': function (state, data) {
      state.actualDataAll = data.map((item) => ({
        ...item,
        date: new Date(`${
          item.yearMonth.toString().slice(0, 4)
        }/${item.yearMonth.toString().slice(4, 6)}/02`),
      }));
    },
    'set:dashboard:is-loading-actual-data': function (state, boolean) {
      state.isLoadingActualData = boolean;
    },
    'set:dashboard:actual-data-dates': function (state, dateList) {
      state.actualDataDates = dateList;
    },
    'set:dashboard:actual-data-selected-date': function (state, date) {
      if (!date) {
        state.actualDataSelectedDate = state.actualDataDates[0];
        return;
      }
      state.actualDataSelectedDate = date;
    },
    'update:dashboard:actual-data-in-selected-date': function (state) {
      const data = state.actualDataAll
        .find((item) => Number(item.date) === state.actualDataSelectedDate.id);

      state.actualDataInSelectedDate = data || null;
    },
  },
  actions: {
    async getDashboardStatistics({ commit, rootState }) {
      const currentLocationId = rootState.user.currentLocation.id;
      const statistics = await dashboardModel.getDashboardStatistics(currentLocationId);
      if (statistics) {
        commit('set:dashboard:statistic', statistics);
      }
    },
    async getDashboardActualData({ commit, dispatch, rootState }, { yearMonth } = {}) {
      if (!yearMonth) {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        // eslint-disable-next-line no-param-reassign
        yearMonth = `${year}${month}`;
      }

      const currentLocationId = rootState.user.currentLocation.id;
      commit('set:dashboard:is-loading-actual-data', true);
      const data = await dashboardModel.getDashboardActualData(currentLocationId, yearMonth);
      if (data) {
        commit('set:dashboard:actual-data-all', data?.[0].incomes || []);
        dispatch('setActualDataDateList');

        commit('set:dashboard:is-loading-actual-data', false);
        commit('set:dashboard:actual-data-selected-date', false);
      }
    },
    async getDashboardActualDataFilter({ commit, rootState }, { yearMonth } = {}) {
      if (!yearMonth) {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        // eslint-disable-next-line no-param-reassign
        yearMonth = `${year}${month}`;
      }

      const currentLocationId = rootState.user.currentLocation.id;
      commit('set:dashboard:is-loading-actual-data', true);
      const data = await dashboardModel.getDashboardActualData(currentLocationId, yearMonth);
      if (data) {
        commit('set:dashboard:actual-data-all', data?.[0].incomes || []);
        commit('set:dashboard:is-loading-actual-data', false);
      }
    },

    setActualDataDateList({ commit, state }) {
      if (state.actualDataAll) {
        const startDate = '2022-01-01T00:00:00';
        const result = generateMonthArray(startDate);
        const day = result.map((item) => {
          const currentDate = new Date(item.date);

          return {
            id: currentDate.getTime(),
            yearMonth: item.yearMonth,
            label: prepareShortMonthDate(currentDate.getTime(), { day: true }),
          };
        });
        commit('set:dashboard:actual-data-dates', day);
      }
    },
    setActualDataSelectedDate({ commit }, date) {
      commit('set:dashboard:actual-data-selected-date', date);
      commit('update:dashboard:actual-data-in-selected-date');
    },
    async getDashboardGraphData({ commit, state, rootState }) {
      if (!state.bookingGraphDateRange) {
        return;
      }

      const currentLocationId = rootState.user.currentLocation.id;

      const data = await dashboardModel
        .getDashboardBookingData({
          startDate: prepareToYYYYMMDD(state.bookingGraphDateRange[0]),
          endDate: prepareToYYYYMMDD(state.bookingGraphDateRange[1]),
          location: currentLocationId,
        });
      if (data) {
        const preparedData = data.map((item) => {
          const { date, bookingAmount } = item;
          const currDate = new Date(+date);
          return {
            label: `${currDate.getDate()} ${MONTHS_SHORT_NAMES.get(currDate.getMonth() + 1)}`,
            amount: bookingAmount,
          };
        });
        commit('set:dashboard:booking-graph-data', preparedData);
      }
    },
    setBookingGraphDateRange({ commit }, dates) {
      commit('set:dashboard:booking-graph-date-range', dates);
    },
  },
};
